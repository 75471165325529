import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/b7146c2/components/layout-content.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Google font pairing`}</h1>
    <p>{`Google Fonts is a free resource for a quickly growing collection of
fonts. `}<inlineCode parentName="p">{`compai`}</inlineCode>{` has curated numerous font pairings from other resources
and implemented an algorithm to randomly select new pairings with high
confidence it will be aesthetically pleasing.`}</p>
    <p>{`Running through font pairings can help you define a brand or feel which
you can then use in projects.`}</p>
    <p>{`Once the font pairing is selected, other `}<a parentName="p" {...{
        "href": "/docs/typography"
      }}>{`typographic scales`}</a>{`
are generated.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/api/generate/google-font-pairing"
        }}><inlineCode parentName="a">{`/api/generate/google-font-pairing`}</inlineCode></a></li>
    </ul>
    <h2>{`Resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://fonts.google.com"
        }}>{`Google Fonts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.typewolf.com/blog/google-fonts-combinations"
        }}>{`Typewolf`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://fontpair.co"
        }}>{`FontPair`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      